import { request, createFormData } from './utils';

import {
  UploadImageRequestOptions,
  ImageResponse,
  UploadFileRequestOptions,
  FileResponse,
  CheckFileOnCloudRequestOptions,
  CheckFileOnCloudResponse,
  CheckFileOnServerRequestOptions,
  CheckFileOnServerResponse,
  CheckChunkListRequestOptions,
  CheckChunkListResponse,
  UploadVideoRequestOptions,
  UploadVideoResponse,
  UploadSubtitleRequestOptions,
  SubtitleResponse
} from './interfaces';

export function uploadImage({
  data
}: UploadImageRequestOptions): Promise<ImageResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: '/files/images',
    method: 'post',
    data: createFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function uploadVideo({
  data
}: UploadVideoRequestOptions): Promise<UploadVideoResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: '/files/videos',
    method: 'post',
    data: createFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function uploadSubtitleFile({
  subtitle,
  type
}: {
  subtitle: File
  type: string
}): Promise<SubtitleResponse> {
  const formData = new FormData();
  const fieldName = type === 'subtitle_cn' ? 'subtitle_cn' : 'subtitle_en';
  formData.append(fieldName, subtitle);

  formData.append('type', type);

  return request({
    url: '/files/subtitles',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(response => {
      console.log('响应数据:', response);
      return response;
    })
    .catch(error => {
      console.error('请求错误:', error);
      throw error;
    });
}

export function uploadFile({
  data
}: UploadFileRequestOptions): Promise<FileResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: '/files/upload',
    method: 'post',
    data: createFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function checkFileOnCloud({
  query
}: CheckFileOnCloudRequestOptions): Promise<CheckFileOnCloudResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: 'files/checkFileOnCloud',
    method: 'get',
    params: query
  });
}

export function checkFileOnServer({
  query
}: CheckFileOnServerRequestOptions): Promise<CheckFileOnServerResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: 'files/checkFileOnServer',
    method: 'get',
    params: query
  });
}

export function checkChunkList({
  query
}: CheckChunkListRequestOptions): Promise<CheckChunkListResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: 'files/checkBlockList',
    method: 'get',
    params: query
  });
}

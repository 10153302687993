import { request } from './utils';

import {
  PaymentsResponse,
  GetPaymentsRequestOptions,
  PaymentResponse,
  GetPaymentRequestOptions,
  AddPaymentRequestOptions
} from './interfaces';

export function getPayment({ id }: GetPaymentRequestOptions): Promise<PaymentResponse> {
  return request({
    url: `/payments/${id}`,
    method: 'get'
  });
}

export function getPayments({ query }: GetPaymentsRequestOptions): Promise<PaymentsResponse> {
  return request({
    url: '/payments',
    method: 'get',
    params: query
  });
}

export function addCredit({ data }: AddPaymentRequestOptions): Promise<PaymentResponse> {
  return request({
    url: '/payments/add-credit',
    method: 'post',
    data
  });
}

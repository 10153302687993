import { request, requestFile } from './utils';
import { saveAs } from 'file-saver';

import {
  GetAnalyticRequestOptions,
  AnalyticResponse,
  RevenueDailyResponse,
  CompanyDataDailyResponse,
  DashboardChannelListResponse,
  GetChannelAnalyticRequestOptions,
  ChannelPieChartRecordsResponse,
  GetChannelTypeRequestOptions,
  ChannelSubscriptionReferralsResponse,
  GetChannelSubscriptionReferralsRequestOptions,
  GetChannelRefundStatisticsRequestOptions,
  ChannelRefundStatisticsResponse
} from './interfaces';

export function getRevenueDaily({ query }: GetAnalyticRequestOptions = {}): Promise<RevenueDailyResponse> {
  return request({
    url: '/analytics/revenue/daily',
    method: 'get',
    params: query
  });
}

export function getCompanyDataDaily({ query }: GetAnalyticRequestOptions = {}): Promise<CompanyDataDailyResponse> {
  return request({
    url: '/analytics/revenue/companyDataDaily',
    method: 'get',
    params: query
  });
}

export async function triggerExportDailyRevenueCsv() {
  try {
    const response = await requestFile.get('/analytics/revenue/exportCsv');
    console.log('response blob 1', response);
    console.log('response blob 2', response.data); // response is the original convertCamelCase(response.data)
    console.log('response.status:', response.status); // response is the original convertCamelCase(response.data), it does NOT have the status

    // if (response.status === 200) {
    const blob = new Blob([response.data], { type: 'text/csv' });
    console.log('response blob 3', blob);

    saveAs(blob, 'dailyRevenue.csv');
    console.log('successful download of CSV blob 3');
    // } else {
    //   console.error('Failed to download CSV');
    // }
    return true; // Successful export
  } catch (error) {
    console.error('CSV download failed:', error);
    return false; // Failed export
  }
}

async function triggerExportCampaignDetailCsv({ query }) {
  try {
    const response = await requestFile.get('/analytics/channel/exportDetailCsv', {
      params: query
    });

    console.log('response:', response);
    console.log('response data:', response.data);
    console.log('response status:', response.status);

    const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
    console.log('CSV blob:', blob);

    saveAs(blob, 'campaign.csv');
    console.log('Successful download of CSV');

    return true;
  } catch (error) {
    console.error('CSV download failed:', error);
    return false;
  }
}

export { triggerExportCampaignDetailCsv };

export async function triggerExportDailyChannelCsv() {
  try {
    const response = await requestFile.get('/analytics/channel/exportCsv');
    console.log('response blob 1', response);
    console.log('response blob 2', response.data);
    console.log('response.status:', response.status);

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'text/csv' });
      console.log('response blob 3', blob);

      saveAs(blob, 'dailyChannel.csv');
      console.log('successful download of CSV blob 3');
    } else {
      console.error('Failed to download CSV');
    }
    return true; // Successful export
  } catch (error) {
    console.error('CSV download failed:', error);
    return false; // Failed export
  }
}

export function getChannelTodayRecord({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/todayRecord',
    method: 'get',
    params: query
  });
}

export function getChannelPeriodStatistic({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/periodStatistic',
    method: 'get',
    params: query
  });
}

export function getChannelRecord({ query }: GetAnalyticRequestOptions = {}): Promise<DashboardChannelListResponse> {
  return request({
    url: '/analytics/channel/records',
    method: 'get',
    params: query
  });
}

export function getUserLineChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/userLineChart',
    method: 'get',
    params: query
  });
}

export function getRevenueLineChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/revenueLineChart',
    method: 'get',
    params: query
  });
}

export function getUserPieChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/userPieChart',
    method: 'get',
    params: query
  });
}

export function getRevenuePieChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/revenuePieChart',
    method: 'get',
    params: query
  });
}

// for Campaign
export function getUserPieChartRecordsByCampaign({
  channelId,
  query
}: GetChannelAnalyticRequestOptions): Promise<ChannelPieChartRecordsResponse> {
  return request({
    url: `/analytics/channel/${channelId}/userPieChart`,
    method: 'get',
    params: query
  });
}

export function getRevenuePieChartRecordsByCampaign({
  channelId,
  query
}: GetChannelAnalyticRequestOptions): Promise<ChannelPieChartRecordsResponse> {
  return request({
    url: `/analytics/channel/${channelId}/revenuePieChart`,
    method: 'get',
    params: query
  });
}

export function getChannelTypePieChart({ query }: GetChannelTypeRequestOptions): Promise<ChannelPieChartRecordsResponse> {
  return request({
    url: '/analytics/channel/type-revenue-distribution',
    method: 'get',
    params: query
  });
}

export function getSubscriptionReferrals({
  query
}: GetChannelSubscriptionReferralsRequestOptions): Promise<ChannelSubscriptionReferralsResponse> {
  return request({
    url: '/analytics/channel/subscriptionReferrals',
    method: 'get',
    params: query
  });
}

export function getRefundStatistics({
  query
}: GetChannelRefundStatisticsRequestOptions): Promise<ChannelRefundStatisticsResponse> {
  return request({
    url: '/analytics/channel/refundStatistics',
    method: 'get',
    params: query
  });
}

import { request, requestFile } from './utils';
import { saveAs } from 'file-saver';

import {
  UserResponse,
  UsersResponse,
  GetUserRequestOptions,
  GetUsersRequestOptions,
  UpdateUserRequestOptions,
  DisableUserRequestOptions,
  SucceededResponse,
  EnableUserRequestOptions,
  SendEmailUserRequestOptions,
  SendResetUserRequestOptions,
  BatchCreateUserRequestOptions,
  GetTokenRecordRequestOptions,
  TokenRecordResponse
} from './interfaces';

export function getUser({ userId }: GetUserRequestOptions): Promise<UserResponse> {
  return request({
    url: `/users/${userId}`,
    method: 'get'
  });
}

export function getUsers({ query }: GetUsersRequestOptions): Promise<UsersResponse> {
  return request({
    url: '/users',
    method: 'get',
    params: query
  });
}

export function getTokenRecord({ query }: GetTokenRecordRequestOptions): Promise<TokenRecordResponse> {
  return request({
    url: '/users/tokenRecord',
    method: 'get',
    params: query
  });
}

async function triggerExportUserCsv({ query }) {
  try {
    const response = await requestFile.get('/users/userRevenueToCSV', {
      params: query
    });

    console.log('response:', response);
    console.log('response data:', response.data);
    console.log('response status:', response.status);

    const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
    console.log('CSV blob:', blob);

    saveAs(blob, 'user_revenue.csv');
    console.log('Successful download of CSV');

    return true;
  } catch (error) {
    console.error('CSV download failed:', error);
    return false;
  }
}

export { triggerExportUserCsv };

export function batchCreateUser({ data }: BatchCreateUserRequestOptions): Promise<UserResponse> {
  return request({
    url: 'users/batch-create',
    method: 'post',
    data
  });
}

export function updateUser({ userId, data }: UpdateUserRequestOptions): Promise<UserResponse> {
  return request({
    url: `/users/${userId}`,
    method: 'put',
    data
  });
}

export function disableUser({ userId }: DisableUserRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/users/${userId}/disable`,
    method: 'post'
  });
}

export function enableUser({ userId }: EnableUserRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/users/${userId}/enable`,
    method: 'post'
  });
}

export function sendEmailUser({ userId }: SendEmailUserRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/users/${userId}/send-email-verification`,
    method: 'get'
  });
}

export function sendResetUser({ userId }: SendResetUserRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/users/${userId}/send-reset-password`,
    method: 'get'
  });
}
